import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class AutoDeliveryNotesProvider extends HttpRequest {
  getAutoDeliveryNotes () {
    this.setHeader(getAuthToken())
    return this.get('auto-delivery-notes')
  }

  getAutoDeliveryNoteById (id) {
    this.setHeader(getAuthToken())
    return this.get(`auto-delivery-notes/${id}`)
  }

  createAutoDeliveryNote (payload) {
    this.setHeader(getAuthToken())
    return this.post('auto-delivery-notes', payload)
  }

  updateAutoDeliveryNote (payload) {
    this.setHeader(getAuthToken())
    return this.put(`auto-delivery-notes/${payload.id}`, payload)
  }

  deleteAutoDeliveryNote (id) {
    this.setHeader(getAuthToken())
    return this.delete('auto-delivery-notes', id)
  }
}

export default AutoDeliveryNotesProvider
