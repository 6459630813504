<template>
  <div class="separate-products-container">
    <v-row class="mx-3 my-3">
      <v-col class="d-flex flex-row align-center">
        <div class="mr-3 w-fit">
          <span class="title">ใบนำเข้า</span>
          <span>{{ note.code }}</span>
        </div>
        <div class="mr-3 w-fit">
          <span class="title">สินค้า</span>
          <span class="mr-1">
            {{ total.product }} แบบ
          </span>
          <span>({{ total.amount }} ชิ้น)</span>
        </div>
        <div class="w-fit">
          <span class="title">% รวม</span>
          <span>{{ totalPercent }}%</span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex flex-row justify-end align-center">
          <v-btn
            class="mx-1"
            color="grey lighten-2"
            elevation="0"
            tile
            small
            @click="mappingSkus(true)">
            กระจายสินค้า
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey lighten-2"
            elevation="0"
            tile
            small
            @click="exportDataToCSV()">
            Export
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey lighten-2"
            elevation="0"
            tile
            small
            @click="confirmMoveProducts()">
            ย้ายสินค้า
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="4">
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th
                  class="text-center header">
                  สถานะ
                </th>
                <th class="text-center header">
                  สินค้า
                </th>
                <th class="text-center header">
                  จำนวน
                </th>
              </tr>
            </thead>
            <tbody
              v-for="(product, productIndex) in products"
              :key="`productIndex-${productIndex}`">
              <tr
                v-for="(item, itemIndex) in product.skus"
                :key="`itemIndex-${itemIndex}`"
                style="height: 57px !important;">
                <td class="text-center row-border">
                  <v-tooltip
                    v-if="item.isError"
                    top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="red"
                        v-bind="attrs"
                        v-on="on">
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>สต็อกไม่พอ</span>
                  </v-tooltip>

                  <v-tooltip
                    v-else
                    top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="success"
                        v-bind="attrs"
                        v-on="on">
                        mdi-check-circle-outline
                      </v-icon>
                    </template>
                    <span>ปกติ</span>
                  </v-tooltip>
                </td>
                <td class="text-center row-border column">
                  <div>
                    {{ item.code }}
                  </div>
                  <div>
                    {{ item.size.toUpperCase() }} - {{ item.color.toUpperCase() }}
                  </div>
                </td>
                <td
                  class="text-center row-border column"
                  :class="(calSkuTotal(item) !== item.count) ? 'text-red' : ''">
                  {{ calSkuTotal(item) }} / {{ item.count }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        cols="8"
        class="overflow-x-auto">
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="branch in selectedBranches"
                  :key="branch.id"
                  class="text-center header">
                  <div class="d-flex flex-column justify-center align-center">
                    <span class="truncate">
                      {{ branch.name }}
                    </span>
                    <div class="d-flex flex-row align-base-line w-full">
                      <v-text-field
                        v-model="branch.percent"
                        dense
                        type="number"
                        min="0"
                        max="100"
                        class="pr-2"
                        :rules="percentRules"
                        suffix="%" />
                      <v-checkbox
                        v-model="branch.isCeil"
                        dense
                        label="ปัดขึ้น"
                        hide-details
                        color="secondary" />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              v-for="(product, productIndex) in products"
              :key="`productIndex-${productIndex}`">
              <tr
                v-for="(item, itemIndex) in product.skus"
                :key="`itemIndex-${itemIndex}`">
                <td
                  v-for="(branch, branchIndex) in item.warehouses"
                  :key="`${item.code}-branch-${branchIndex}`"
                  class="row-border branch">
                  <v-text-field
                    v-model.number="branch.amount"
                    outlined
                    dense
                    type="number"
                    min="0"
                    hide-details
                    full-width
                    class="my-2"
                    @input="branch.amount === '' ? branch.amount = 0 : () => {}" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-overlay
      :value="loading"
      z-index="10">
      <gw-svg-icon
        class="cart-loading"
        svg-name="cart-loading"
        original />
    </v-overlay>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */

import { mapGetters, mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import AutoDeliveryNotesProvider from '@/resources/AutoDeliveryNotesProvider'
import { ExportToCsv } from 'export-to-csv'

const ProductService = new ProductProvider()
const AutoDeliveryNotesService = new AutoDeliveryNotesProvider()

export default {
  props: {
    note: {
      type: Object,
      default: () => {}
    },
    selectedBranches: {
      type: Array,
      default: () => []
    },
    warehouseId: {
      type: Number,
      default: null
    },
    total: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      products: [],
      branches: [],
      deliveryList: [],
      withdrawList: [],
      loading: false,
      percentRules: [
          (v) => !!v || 'This field is required',
          (v) => (v && v >= 0) || 'Enter more than 0',
          (v) => (v && v <= 100) || 'Enter less than 100'
      ]
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse'
    }),
    totalPercent () {
      return this.selectedBranches.reduce((sum, branch) => sum + (+branch.percent), 0).toFixed(2)
    }
  },
  mounted () {
    this.initBranches()
    this.mappingSkus()
    this.checkStock()
  },
  methods: {
    calSkuTotal (sku) {
      const total = sku.warehouses.reduce((sum, warehouse) => sum + warehouse.amount, 0)

      return total
    },
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    initBranches () {
      this.selectedBranches.forEach((branch) => {
        const tmpBranch = branch
        tmpBranch.isCeil = false
      })
    },
    mappingSkus (reSeparate = false) {
      if (!reSeparate) {
        this.products = this.separateProducts(this.note.products)
      } else {
        this.products = this.separateProducts(this.products)
      }
    },
    separateProducts (products) {
      const tmpSelectedBranches = this.selectedBranches.sort((a, b) => b.percent - a.percent)
      const finalResult = products.map((product) => {
        const result = product
        result.skus = result.skus.map((sku) => {
          const tempSku = JSON.parse(JSON.stringify(sku))
          tempSku.warehouses = []
          tempSku.isError = tempSku.isError ? tempSku.isError : false
          const seperateByPercent = tempSku.amount > tmpSelectedBranches.length
          let assinedAmount = 0

          for (const branch of tmpSelectedBranches) {
            const remainAmount = (tempSku.amount - assinedAmount) > 0 ? tempSku.amount - assinedAmount : 0
            const tempWarehouse = JSON.parse(JSON.stringify(branch))

            const separateAmount = branch.isCeil
              ? Math.ceil(tempSku.count * (+tempWarehouse.percent / 100))
              : Math.floor(tempSku.count * (+tempWarehouse.percent / 100))
            tempWarehouse.amount = (remainAmount > 0) && (separateAmount <= remainAmount) && seperateByPercent
              ? separateAmount
              : 0

            assinedAmount += tempWarehouse.amount
            tempSku.warehouses.push(tempWarehouse)
          }

          let totalLoop = tempSku.count - assinedAmount
          let skuWarehouseIndex = 0
          while (totalLoop > 0) {
            tempSku.warehouses[skuWarehouseIndex].amount += 1
            skuWarehouseIndex++
            if (skuWarehouseIndex >= tempSku.warehouses.length) {
              skuWarehouseIndex = 0
            }
            totalLoop--
            if (totalLoop === 0) {
              break
            }
          }

          return tempSku
        })

        return result
      })

      return finalResult
    },
    async checkStock () {
      try {
        this.loading = true

        const productIds = this.products.map((product) => product.id)
        let hasError = false
        const { data: fetchProducts } = await ProductService.getManyProductById({ productIds })
        this.products = this.products.map((pd) => {
          const tempPd = pd
          const matchProduct = fetchProducts.find((p) => p.id === pd.id)
          tempPd.skus = tempPd.skus.map((sku) => {
            const tempSku = JSON.parse(JSON.stringify(sku))
            const matchSku = matchProduct.skus.find((sk) => sk.id === tempSku.id)
            const stock = matchSku.stock.find((w) => w.warehouse.id === this.warehouseId)
            const stockQty = stock.onHandQty - stock.onReservedQty
            if (this.calSkuTotal(tempSku) > stockQty) {
              hasError = true
              tempSku.isError = true
            } else {
              tempSku.isError = false
            }

            return tempSku
          })
          return tempPd
        })

        if (hasError) {
          const error = { code: 400, message: 'Amount product is not enough.' }
          throw error
        }

        return true
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })

        return false
      } finally {
        this.loading = false
      }
    },
    confirmMoveProducts () {
      this.setModal({
        value: true,
        title: 'Confirmation',
        message: 'Are you sure to do this action',
        confirmText: 'Confirm',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.moveProducts()
      })
    },
    async moveProducts () {
      const isEnough = await this.checkStock()

      if (isEnough) {
        this.createNote()
      }
    },
    findWarehouse (id) {
      const warehouse = this.warehouse.find((r) => r.id === id)
      return {
        id: warehouse.id,
        name: warehouse.name,
        code: warehouse.code
      }
    },
    optimizePayload () {
      const tmpProducts = JSON.parse(JSON.stringify(this.products))
      const cleanProducts = tmpProducts
        .map((product) => ({
          id: product.id,
          name: product.name,
          model: product.model,
          photoUrl: product.photoUrl,
          skus: product.skus.filter((sku) => this.calSkuTotal(sku) > 0)
        }))
        .filter((product) => product.skus.length > 0)

      if (!cleanProducts.length) {
        this.setSnackbar({
          value: true,
          message: 'Not enough products to create.',
          type: 'error'
        })

        return []
      }

      let warehouses = this.selectedBranches.map((warehouse) => {
        const payload = {
          warehouse: {
            id: warehouse.id,
            name: warehouse.name,
            code: warehouse.code
          },
          products: []
        }

        for (const product of cleanProducts) {
          const tmpProduct = {
            ...product,
            skus: []
          }

          for (const sku of product.skus) {
            const found = sku.warehouses.find((w) => w.id === warehouse.id)

            if (found && found?.amount > 0) {
              tmpProduct.skus.push({
                id: sku.id,
                code: sku.code,
                price: sku.price,
                option: sku.option,
                color: sku.color,
                size: sku.size,
                specialPrice: sku.specialPrice,
                amount: found.amount,
                count: 0
              })
            }
          }

          if (tmpProduct.skus.length) {
            payload.products.push(tmpProduct)
          }
        }

        return payload
      })

      warehouses = warehouses.filter((warehouse) => warehouse.products.length > 0)

      return warehouses
    },
    async createNote () {
      try {
        this.loading = true

        const { data } = await AutoDeliveryNotesService.createAutoDeliveryNote({
          deliveryNoteId: this.note.id,
          totalProduct: this.total.product,
          totalAmount: this.total.amount,
          fromWarehouse: this.findWarehouse(this.warehouseId),
          toWarehouses: this.optimizePayload()
        })

        this.setSnackbar({
          value: true,
          message: 'Create delivery and withdraws notes success.',
          type: 'success'
        })

        this.$emit('summary', [data])
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    exportDataToCSV () {
      const items = []
      const products = JSON.parse(JSON.stringify(this.products))
      products.forEach((product) => {
        product.skus.forEach((sku) => {
          const tmpSku = {
            name: product.name,
            code: sku.code,
            size: sku.size,
            color: sku.color,
            total: this.calSkuTotal(sku),
            count: sku.count
          }

          sku.warehouses.forEach((warehouse) => {
            tmpSku[`${warehouse.name}`] = warehouse.amount
          })

          items.push(tmpSku)
        })
      })
      const options = {
        filename: `Stock_${this.note.code}`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(items)
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.w-fit {
  width: fit-content
}
.overflow-x-auto {
  overflow-x: auto;
}
.separate-products-container {
  height: calc(100vh - 68px);
  overflow-y: auto;
}
.title {
  font-weight: 600;
  padding-right: 5px;
}
.text-red {
  color: red !important;
}
.header {
  height: 120px !important;
  font-size: 1rem !important;
}
.row-border {
  border-bottom: 1px solid grey;
}
.column {
  min-width: 120px;
}
.column:nth-child(1) {
  min-width: 250px;
}
.branch {
  min-width: 250px;
}
.truncate {
  width: 218px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
