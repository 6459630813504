<template>
  <v-container>
    <v-row
      v-for="(summaryNote, i) in summaryNotes"
      :key="i">
      <v-col cols="12">
        <v-row class="mx-3 my-3">
          <v-col>
            <span class="title">วันที่สร้าง</span>
            <span>{{ summaryNote.createdAt | dateTimeUserFormat() }}</span>
          </v-col>
          <v-col>
            <span class="title">เลขที่ใบนำเข้า</span>
            <span>{{ noteCode }}</span>
          </v-col>
          <v-col>
            <span class="title">จำนวนสินค้า</span>
            <span>{{ summaryNote.productTotal }} แบบ</span>
          </v-col>
          <v-col>
            <span class="title">จำนวนชิ้น</span>
            <span>{{ summaryNote.skuTotal }} ชิ้น</span>
          </v-col>
        </v-row>
        <v-row class="mx-3 my-3">
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th>จำนวนชิ้น</th>
                    <th>เลขที่ใบนำออก</th>
                    <th>เลขที่ใบนำเข้า</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(w, index) in summaryNote.toWarehouses"
                    :key="index">
                    <td>{{ w.warehouse.name }}</td>
                    <td>{{ w.skuAmount }}</td>
                    <td>{{ avoidNullValue(w.withdrawNote, 'code') }}</td>
                    <td>{{ avoidNullValue(w.deliveryNote, 'code') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    noteCode: {
      type: String,
      default: ''
    },
    summaryNotes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      total: {
        product: 0,
        amount: 0
      },
      branches: []
    }
  },
  methods: {
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
  padding-right: 5px;
}
</style>
